exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-become-a-partner-js": () => import("./../../../src/pages/become-a-partner.js" /* webpackChunkName: "component---src-pages-become-a-partner-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-broker-partnership-js": () => import("./../../../src/pages/broker-partnership.js" /* webpackChunkName: "component---src-pages-broker-partnership-js" */),
  "component---src-pages-career-job-name-js": () => import("./../../../src/pages/career/{job.name}.js" /* webpackChunkName: "component---src-pages-career-job-name-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investor-relations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-news-template-jsx": () => import("./../../../src/templates/NewsTemplate.jsx" /* webpackChunkName: "component---src-templates-news-template-jsx" */)
}

